<template>
  <Modal
    :activeModal="activeModal"
    :widthModal="450"
    @closeModal="closeModal">
    <template v-slot:btnActivator>
      <v-btn
        tile
        v-if="permiteAcao($route, 'add')" 
        :color="variables.colorPrimary"
        dark
        class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        :loading="loadingBtnCadastro">Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ isEdit ? 'Editar Device' : 'Cadastrar Device' }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-form>
        <!-- <v-card-title class="modal-cadastro-titulo">
          
        </v-card-title> -->

        <v-card-text class="modal-cadastro-form">
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-select :error-messages="deviceTypesErrors" :items="deviceTypesItensSelect" label="Tipo de device" v-model="item.deviceTypeId" @input="$v.item.deviceTypeId.$touch()" @blur="$v.item.deviceTypeId.$touch()"></v-select>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput"><v-text-field v-model="item.deviceVersion" label="Versão do device" @keypress="disableSubmit"/></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput"><v-text-field :error-messages="hwSerialErrors" v-model="item.hwSerial" label="Código" @input="$v.item.hwSerial.$touch()" @blur="$v.item.hwSerial.$touch()" @keypress="disableSubmit" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput"><v-text-field :error-messages="serialErrors" v-model="item.serial" label="Serial:" @input="$v.item.serial.$touch()" @blur="$v.item.serial.$touch()" @keypress="disableSubmit" /></v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'

import validacao from './validacao'

export default {
  name: 'BrModalCadastroOSC',
  mixins: [validationMixin],
  directives: {mask},
  components: {
    Modal: () => import('@/views/components/modal')
  },
  data: () => ({
    activeModal: false,
    loading: false
  }),
  computed: {
    ...mapGetters('devices', ['item']),
    ...mapGetters('deviceTypes', { deviceTypesItensSelect: 'listaItensSelect' }),
    ...mapGetters('roles', ['permiteAcao']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    }
  },
  mounted () {
    this.getDeviceTypes()
    Events.$on('cadastro::openModalCadastro', () => {
      this.activeModal = true
    })
  },
  methods: {
    ...mapActions('devices', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem']),
    ...mapActions('deviceTypes', {
      getDeviceTypes: 'getItens',
    }),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },

    submit () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.loading = true

      const dados = {
        deviceVersion: this.item.deviceVersion,
        serial: this.item.serial,
        deviceTypeId: this.item.deviceTypeId,
        hwSerial: this.item.hwSerial
      }

      if (this.item.id) {
        dados.id = this.item.id

        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      } else {
        this.cadastrarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }

    },

    disableSubmit (e) {
      if(e.which == 13) {
        //cancela a ação padrão
        event.preventDefault();
      }
    }
  },

  validations () {
    return  {
      item: {
        serial: { required },
        deviceTypeId: { required },
        hwSerial: {required}
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/formularios';
  @import '../../../../../assets/styles/components/modal-cadastro';
</style>