export default {
  // validações dados ats instituição
  devicesErrors () {
    const errors = []
    if (!this.$v.item.deviceVersion.$dirty) return errors
    !this.$v.item.deviceVersion.required && errors.push('O número do serial é obrigatório.')
    return errors
  },

  // validações dados ats representante
  serialErrors () {
    const errors = []
    if (!this.$v.item.serial.$dirty) return errors
    !this.$v.item.serial.required && errors.push('O número do serial é obrigatório.')
    return errors
  },

  deviceTypesErrors () {
    const errors = []
    if (!this.$v.item.deviceTypeId.$dirty) return errors
    !this.$v.item.deviceTypeId.required && errors.push('Selecione o tipo de device.')
    return errors
  },

  hwSerialErrors () {
    const errors = []
    if (!this.$v.item.hwSerial.$dirty) return errors
    !this.$v.item.hwSerial.required && errors.push('Dígite o código do device.')
    return errors
  },
}
